import numeral from 'numeral';

export const numberWithCommas = (num?: number | null) => {
  if (!num) {
    return 0;
  }
  return numeral(num).format('0,0');
};

export const numberWithPlusSignByMaxCount = (num: number, max_count = 9999) => {
  return `${numberWithCommas(numberLimitMax(num, max_count))}${num > max_count ? '+' : ''}`;
};

export const isNumberOnly = (num: string) => {
  const reg = new RegExp('^[0-9]');
  return reg.test(num);
};

export const getNumberOnly = (num: string) => {
  return num.replace(/[^0-9]/g, '');
};

export const numberInTenThousand = (num: number | null) => {
  if ((typeof num === 'number' && num < 0) || num === null) {
    return 0;
  }

  const unitWords = ['', '만'];
  const splitUnit = 10000;

  return unitWords
    .map((_, i) => {
      const unitResult = Math.floor((num % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i));
      if (unitResult > 0) {
        return unitResult;
      }
      return undefined;
    })
    .reduce((acc, cur, i) => {
      if (cur) {
        return String(cur) + unitWords[i] + acc;
      }
      return acc;
    }, '');
};

export const numberInTenThousandWithFloor = (num: number | null) => {
  if ((typeof num === 'number' && num < 0) || num === null) {
    return 0;
  }

  const splitUnit = 10000;

  if (num < splitUnit) {
    return numberWithCommas(num);
  }

  const unitized_value = num / splitUnit;
  const fixed_value = Math.floor(unitized_value * 10) / 10;

  return `${fixed_value}만`;
};

export const numberLimitMax = (num: number, limit = 9999) => {
  return num > limit ? limit : num;
};
