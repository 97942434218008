import { useEffect, useState } from 'react';

import { BannerGroup, BannerGroupBannerList } from '@domains/storehome-common';

const MESSAGE_FIELDS = ['icrop_image_blob', 'id', 'image_url', 'link', 'link_type', 'name', 'title'];

export function useBannerGroupPreview(is_preview: boolean, banner_group: BannerGroup | null): BannerGroup | null {
  const [changed_banner_group, setChangedBannerGroup] = useState<BannerGroup | null>(null);
  useEffect(() => {
    if (!is_preview) return;
    const receiveMessage = (event: MessageEvent) => {
      if (!MESSAGE_FIELDS.every((key) => key in event.data)) {
        return;
      }
      const { icrop_image_blob, ...banner_item } = event.data;

      const banner_list = [
        {
          ...banner_item,
          ...(icrop_image_blob ? { image_url: URL.createObjectURL(icrop_image_blob) } : {}),
        } as BannerGroupBannerList,
      ];

      setChangedBannerGroup({
        auto_rolling_interval: 4,
        is_auto_rolling: false,
        banner_list,
      });
    };

    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [is_preview]);

  if (!is_preview) {
    return banner_group;
  }
  return changed_banner_group ?? banner_group;
}
