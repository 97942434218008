import { wrapEnvConfigContext } from '@domains/storehome-common';
import { SeoContainer } from '@domains/storehome-feature';
import { getStoreHomePageServerSideProps, StoreHomePage, StoreHomePageProps } from '@domains/storehome-screen';

const StoreHomePageWrapper = (props: StoreHomePageProps) => {
  return (
    <>
      <SeoContainer
        main_domain={props.main_domain}
        shop_information={props.shop_information}
        image={props.banner_group?.banner_list?.[0]?.image_url ?? ''}
      />
      <StoreHomePage {...props} />;
    </>
  );
};

export default wrapEnvConfigContext({ is_zigzag: false }, StoreHomePageWrapper);
export const getServerSideProps = getStoreHomePageServerSideProps;
