import { FC } from 'react';
import styled from '@emotion/styled';
import NextLink from 'next/link';

import { useZigzag } from '@common/device-manager';
import { Gray_Light_300, Gray_Light_900, Gray_Light_White, Typography } from '@common/styles';

import { StoreHomeUBLCategory } from '../../hooks/useStoreHomeUBL';
import { HEADER_HEIGHT } from '../header';

interface NavigationList {
  id: string;
  name: string;
}

interface Props {
  navigation_list: NavigationList[];
  hrefCallback?: (id: string) => string;
  active_navigation_id?: string;
  sendUBL?: (category: StoreHomeUBLCategory) => void;
}

export const Navigation: FC<Props> = ({
  navigation_list,
  hrefCallback: _hrefCallback,
  active_navigation_id,
  sendUBL,
}) => {
  const is_app = useZigzag(false);
  const active_id = active_navigation_id || navigation_list[0]?.id;
  const hrefCallback = _hrefCallback ? _hrefCallback : (id: string) => id;

  if (navigation_list.length <= 0) {
    return null;
  }

  return (
    <NavigationBar is_app={!!is_app}>
      <ul>
        {navigation_list.map(({ id, name }, index) => (
          <Item className={Typography.BODY_16_SEMIBOLD} key={id} is_active={active_id === id}>
            {id === '0' ? (
              <span>{name}</span>
            ) : (
              <NextLink href={hrefCallback(id)}>
                <a
                  onClick={() => {
                    sendUBL?.({ id, name, index });
                  }}
                >
                  {name}
                </a>
              </NextLink>
            )}
          </Item>
        ))}
      </ul>
    </NavigationBar>
  );
};

const NavigationBar = styled.nav<{ is_app: boolean }>`
  position: sticky;
  z-index: 11;
  height: 48px;
  box-sizing: border-box;
  top: ${({ is_app }) => (is_app ? 0 : HEADER_HEIGHT)}px;

  ul {
    display: flex;
    align-items: center;
    background-color: ${Gray_Light_White};
    padding: 4px 8px;
    height: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const Item = styled.li<{ is_active: boolean }>`
  flex: 0 0 auto;
  padding: 10px 8px;

  a {
    width: 100%;
    height: 100%;
    text-align: left;
    color: ${(props) => (props.is_active ? Gray_Light_900 : Gray_Light_300)};
  }
`;
