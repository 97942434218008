import React from 'react';
import { Text } from '@croquiscom-pvt/zds';
import styled from '@emotion/styled';
interface BannerCountProps {
  active_index: number;
  total_count: number;
}

export const BannerCount: React.FC<BannerCountProps> = ({ active_index, total_count }) => {
  if (!total_count) {
    return null;
  }

  return (
    <Wrapper>
      <Number className='active'>{active_index + 1}</Number>
      <Number>/{total_count}</Number>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 10px;
  z-index: 1;
  min-width: 38px;
  height: 22px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 80px;
`;

const Number = styled(Text)`
  opacity: 0.7;

  &.active {
    opacity: 1;
  }
`;
Number.defaultProps = {
  variant: 'Caption_12_Medium',
  v3Color: 'staticWhite',
};
