import { NextSeo, SiteLinksSearchBoxJsonLd } from 'next-seo';

import { ShopInfo } from '@domains/storehome-common';

import { useMetaDataService } from '../services/useMetaDataService';

export interface SeoProps {
  main_domain: string;
  shop_information: ShopInfo;
  image?: string;
}

export const SeoContainer = ({ main_domain, shop_information, image }: SeoProps) => {
  const { title, description, keywords, shopUrl } = useMetaDataService(main_domain, shop_information);

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title: title,
          description: description,
          ...(image && { images: [{ url: image, width: 800, height: 400, alt: title, type: 'image/jpeg' }] }),
          url: shopUrl,
          type: 'website',
        }}
        additionalMetaTags={[
          {
            property: 'keywords',
            content: keywords,
          },
        ]}
      />
      <SiteLinksSearchBoxJsonLd
        useAppDir={true}
        url={shopUrl}
        potentialActions={[
          {
            target: `${shopUrl}/search?keyword={search_term_string}`,
            queryInput: 'search_term_string',
          },
        ]}
      />
    </>
  );
};
