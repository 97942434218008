import { useEffect, useRef } from 'react';
import { dehydrate, Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { GetServerSideProps, NextPage } from 'next';

import {
  BannerGroup,
  CategoryList,
  fetchShopIdByMainDomain,
  fetchStoreInfo,
  getComponentListInfiniteQueryOptions,
  Layout,
  NetworkOfflineError,
  ServerError,
  ShopInfo,
  StoreHomeHeader,
  useEnvConfig,
  useNetworkStatus,
  useStoreHomeUBL,
} from '@domains/storehome-common';
import { StoreHomeView } from '@domains/storehome-feature';

export interface StoreHomePageProps {
  shop_id: string;
  main_domain: string;
  shop_information: ShopInfo;
  banner_group: BannerGroup | null;
  category_list: CategoryList[];
  is_preview?: boolean;
  error?: unknown;
  dehydrated_state: unknown;
}

export const StoreHomePage: NextPage<StoreHomePageProps> = ({
  is_preview,
  error,
  shop_id,
  main_domain,
  banner_group,
  category_list,
  shop_information,
  dehydrated_state,
}) => {
  const queryClientRef = useRef<QueryClient>(
    new QueryClient({ defaultOptions: { queries: { staleTime: 10000, refetchOnWindowFocus: false } } }),
  );

  const { isOffline } = useNetworkStatus();
  const { is_zigzag } = useEnvConfig();
  const { pageviewUBL } = useStoreHomeUBL(shop_id);

  useEffect(() => {
    if (!is_preview) {
      pageviewUBL('shop_detail', window.location.href, {
        is_opened: !error,
      });
    }
  }, []);

  if (error) {
    return <ServerError />;
  }
  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={dehydrated_state}>
        <Layout>
          {isOffline ? (
            <NetworkOfflineError />
          ) : (
            <>
              {!is_zigzag && !is_preview && (
                <StoreHomeHeader main_domain={main_domain} show_search_button={shop_information.show_search_button} />
              )}
              <StoreHomeView
                banner_group={banner_group}
                category_list={category_list}
                shop_info={shop_information}
                is_preview={is_preview}
              />
            </>
          )}
        </Layout>
      </Hydrate>
    </QueryClientProvider>
  );
};

export const getStoreHomePageServerSideProps: GetServerSideProps<Partial<StoreHomePageProps>> = async ({
  query,
  req,
}) => {
  const main_domain = String(query.main_domain);
  const is_preview = typeof query.preview === 'string' ? Boolean(query.preview) : false;

  try {
    const shop_id = await fetchShopIdByMainDomain(main_domain, req);
    const client = new QueryClient();
    const [store_info] = await Promise.all([
      fetchStoreInfo(shop_id, req),
      client.fetchInfiniteQuery(
        getComponentListInfiniteQueryOptions({ shop_id, check_button_item_ids: [], sorting_item_id: null }, req),
      ),
    ]);
    if (store_info == null) {
      throw new Error('존재하지 않는 스토어입니다.');
    }
    const {
      shop_ux_shop: { banner_group, ...shop_information },
      shop_ux_category_list: category_list,
    } = store_info;

    return {
      props: {
        shop_id,
        main_domain,
        shop_information,
        banner_group: is_preview ? null : banner_group,
        category_list,
        is_preview,
        dehydrated_state: JSON.parse(JSON.stringify(dehydrate(client))),
      },
    };
  } catch (error) {
    return {
      props: {
        error,
        shop_id: '',
        main_domain: '',
      },
    };
  }
};

export const getStoreHomeByIdPageServerSideProps: GetServerSideProps<Partial<StoreHomePageProps>> = async ({
  query,
  req,
}) => {
  const shop_id = String(query.shop_id);
  const is_preview = typeof query.preview === 'string' ? Boolean(query.preview) : false;

  try {
    const client = new QueryClient();
    const [store_info] = await Promise.all([
      fetchStoreInfo(shop_id, req),
      client.fetchInfiniteQuery(
        getComponentListInfiniteQueryOptions({ shop_id, check_button_item_ids: [], sorting_item_id: null }, req),
      ),
    ]);
    if (store_info == null) {
      throw new Error('존재하지 않는 스토어입니다.');
    }
    const {
      shop_ux_shop: { banner_group, ...shop_information },
      shop_ux_category_list: category_list,
    } = store_info;

    return {
      props: {
        shop_id,
        main_domain: shop_information.id,
        shop_information,
        banner_group: is_preview ? null : banner_group,
        category_list,
        is_preview,
        dehydrated_state: JSON.parse(JSON.stringify(dehydrate(client))),
      },
    };
  } catch (error) {
    return {
      props: {
        error,
        shop_id: '',
        main_domain: '',
      },
    };
  }
};
