import { ShopInfo } from '@domains/storehome-common';

export const useMetaDataService = (main_domain: string, shop_information: ShopInfo) => {
  const title = shop_information?.name ? `${shop_information.name} | 지그재그 스토어` : '지그재그 스토어';
  const description = shop_information?.comment
    ? `${shop_information.comment}`
    : ' 4,000만 여성이 선택한 올인원 쇼핑 앱 지그재그 - 제가 알아서 살게요.';
  const keywords = shop_information?.style_list ? shop_information.style_list.map(({ value }) => value).join(',') : '';
  const shopUrl = `https://zigzag.kr/${main_domain}`;

  return { title, description, keywords, shopUrl };
};
