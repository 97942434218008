import { FC, useCallback } from 'react';
import styled from '@emotion/styled';

import { Gray_Light_White, Typography } from '@common/styles';

import detailCouponCenter from './image/detail_coupon_center.svg';
import detailCouponLeft from './image/detail_coupon_left.svg';
import detailCouponRight from './image/detail_coupon_right.svg';

interface Props {
  title: {
    text: string;
    is_html: boolean;
  };
  link: string;
  sendUBL?: () => void;
}

/*
 * Nine Patch 이미지와 비슷한 효과로 높이 고정에 좌우로 늘어나는 형태의 Icon 기획
 * 초기에 Multiple Background Images css 효과로 작업하였으나
 * IOS Safari에서 정상 동작하지 않아 before(left), tag(center), after(right) 형태로 변경
 */
export const CouponIcon: FC<Props> = ({ title, link, sendUBL }) => {
  const renderTitle = useCallback(() => {
    const { is_html, text } = title;

    if (!is_html) {
      return <Title>{text}</Title>;
    }

    return <Title dangerouslySetInnerHTML={{ __html: text }} />;
  }, [title]);

  return (
    <CouponWrap className={Typography.BODY_13_BOLD} href={link} target='_blank' onClick={sendUBL}>
      <Icon>
        <IconCenter />
      </Icon>

      {renderTitle()}
    </CouponWrap>
  );
};

const CouponWrap = styled.a`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${Gray_Light_White};
`;

const Icon = styled.i`
  display: flex;
  position: relative;
  width: 100%;
  height: 48px;

  &::before {
    display: block;
    content: '';
    background-image: url(${detailCouponLeft});
    width: 20px;
  }

  &::after {
    display: block;
    content: '';
    background-image: url(${detailCouponRight});
    width: 56px;
  }
`;

const IconCenter = styled.i`
  flex: 1;
  background-image: url(${detailCouponCenter});
`;

const Title = styled.p`
  position: absolute;
  overflow: hidden;
  padding: 0 72px 0 16px;
  width: 100%;
  line-height: 48px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
