import React from 'react';
import { IconProps } from '.';
import Svg from '../Svg';
const SvgIconStarBold32 = ({ size = 32, color, ...props }: IconProps): JSX.Element => {
  return (
    <Svg width={size} height={size} fill='none' viewBox='0 0 32 32' color={color} {...props}>
      <path
        stroke='currentColor'
        strokeWidth={2}
        d='m16 7.26 2.63 5.329.233.47.52.077 5.88.854-4.255 4.148-.376.367.089.518 1.004 5.857-5.26-2.765L16 21.87l-.465.245-5.26 2.765 1.004-5.857.09-.518-.377-.367-4.256-4.148 5.881-.854.52-.076.233-.472L16 7.26Z'
      />
    </Svg>
  );
};
export default SvgIconStarBold32;
