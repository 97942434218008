import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { RequestContext } from '@common/api-request';

import { getComponentList } from '../../graphql/index.2';
import { GetComponentList } from '../../graphql/index_types.2';

export interface UseGetComponentListInfiniteQueryData extends NonNullable<GetComponentList['shop_ux_component_list']> {}

export interface UseGetComponentListInfiniteQueryParams {
  shop_id: string;
  category_id?: string | null;
  sorting_item_id?: string | null;
  check_button_item_ids?: (string | null)[] | null;
}

export function getComponentListInfiniteQueryOptions(
  params: UseGetComponentListInfiniteQueryParams,
  context?: RequestContext,
): UseInfiniteQueryOptions<UseGetComponentListInfiniteQueryData> {
  return {
    queryKey: ['GetComponentList', params],
    queryFn: async ({ pageParam }) => {
      const { data } = await getComponentList({ ...params, after_id: pageParam }, { context });
      const {
        after_id = null,
        has_next_page = false,
        item_list = [],
        category_list = [],
      } = data.shop_ux_component_list ?? {};
      return { after_id, has_next_page, item_list, category_list };
    },
    getNextPageParam: (lastPage) => (lastPage.has_next_page ? lastPage.after_id : undefined),
    keepPreviousData: true,
  };
}

export function useGetComponentListInfiniteQuery(params: UseGetComponentListInfiniteQueryParams) {
  return useInfiniteQuery(getComponentListInfiniteQueryOptions(params));
}
